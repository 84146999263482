<template>
    <div class="edit_details" v-if="modelValue">
        <div class="header">
            <h1>Subscription #{{ selectedOrder.id }}</h1>
            <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
        </div>
        <div class="edit_wpr">
            <div class="product_details">
                <h3 class="sub_header">Billing Details</h3>
                <ul class="listing">
                    <li>
                        <h6>Billed To</h6>
                        <h4>{{ selectedClient.full_name }}</h4>
                        <h4>{{ selectedClient.email }}</h4>
                    </li>
                    <li>
                        <h6>Product Details</h6>
                        <h4>{{ selectedOrder.product ? selectedOrder.product.name : '-' }}</h4>
                    </li>
                    <li>
                        <h6>Subscription Price</h6>
                        <h4>${{ selectedOrder.price ? parseFloat(selectedOrder.price).toFixed(2) : '0.00' }}</h4>
                    </li>
                    <li>
                        <h6>Subscription Status</h6>
                        <h4>{{  Helper.capitalizeFirst(selectedOrder.status) }}</h4>
                    </li>
                </ul>
                <h3 class="sub_header">Trial Configuration</h3>
                <ul class="billing">
                    <li>
                        <h6>Trial Start Date</h6>
                        <h4>{{  selectedOrder.trial_start ? moment(selectedOrder.trial_start).format('ll') : '-' }}</h4>
                    </li>
                    <li>
                        <h6>Trial End Date</h6>
                        <h4>{{  selectedOrder.trial_end ? moment(selectedOrder.trial_end).format('ll') : '-' }}</h4>
                    </li>
                    <li>
                        <h6 class="mb-2">Change Trial End Date</h6>
                        <datepicker v-model="trialEndDate" :min-date="selectedOrder.trial_start" @update:model-value="handleTrialEndDate" :enable-time-picker="false" placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                    </li>
                    <!-- <li>
                        <h6>Pause Trial</h6>
                        <label for="trial_pause" class="switch_option capsule_btn p-0">
                            <input type="checkbox" id="trial_pause" v-model="pauseTrial" :true-value="1" :false-value="0" @change="handleOrderTrialStatus" hidden>
                            <div><span></span></div>
                        </label>
                    </li> -->
                </ul>
                <h3 class="sub_header">Billing Configuration</h3>
                <ul class="billing">
                    <li>
                        <h6>Billing Start Date</h6>
                        <h4>{{  selectedOrder.billing_start_date ? moment(selectedOrder.billing_start_date).format('ll') : '-' }}</h4>
                    </li>
                    <li>
                        <h6>Next Billing Date</h6>
                        <h4 v-if="selectedOrder.is_billing_pause">-</h4>
                        <h4 v-else>{{  selectedOrder.next_billing_date ? moment(selectedOrder.next_billing_date).format('ll') : '-' }}</h4>
                    </li>
                    <li>
                        <h6 class="mb-2">Change Next Billing Date</h6>
                        <datepicker v-model="nextBillingDate" :min-date="selectedOrder.billing_start_date" :disabled="Boolean(selectedOrder.is_billing_pause)" @update:model-value="handleNextBillingDate" :enable-time-picker="false"  placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                    </li>
                    <li>
                        <h6>Pause Billing</h6>
                        <label for="billing_pause" class="switch_option capsule_btn p-0">
                            <input type="checkbox" id="billing_pause" v-model="pauseBilling" :true-value="1" :false-value="0" @change="handleOrderBillingStatus" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="result_wpr mb-5">
                <div class="title">Trial Summary</div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Product Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="trialSubscriptionLogs.total">
                        <tr v-for="(subscriptionLog, s) in trialSubscriptionLogs.data" :key="s">
                            <td>{{ moment(subscriptionLog.created_at).format('ll') }}</td>
                            <td>{{ selectedOrder.product ? selectedOrder.product.name : '-' }}</td>
                            <td>{{ subscriptionLog.trial_start ? moment(subscriptionLog.trial_start).format('ll') : '-' }}</td>
                            <td>{{ subscriptionLog.trial_end ? moment(subscriptionLog.trial_end).format('ll') : '-' }}</td>
                            <td>
                                <ul class="action_list">
                                    <!-- <li title="Refund" @click="handlePaidSubscriptionRefund(subscriptionLog)"><i class="fas fa-reply"></i></li> -->
                                    <li title="Send Invoice" @click="sendSubscriptionChargeInvoice(subscriptionLog.id)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != subscriptionLog.id, 'fa fa-spin fa-spinner': sendInvoiceLoader == subscriptionLog.id }"></i></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination" v-show="trialSubscriptionLogs.total">
                <pagination v-model="trialSubscriptionParams.page" :pages="trialSubscriptionLogs.last_page" @update:modelValue="handlePagination('trialing')" />
            </div>
            <div class="result_wpr mb-5">
                <div class="title">Subscription Summary</div>
                <div class="contact_loader" v-if="paidSubscriptionLogsLoader"><quote-loader /></div>
                <table v-show="!paidSubscriptionLogsLoader">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Product Name</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="paidSubscriptionLogs.total">
                        <tr v-for="(subscriptionLog, s) in paidSubscriptionLogs.data" :key="s">
                            <template v-if="subscriptionLog.status != 'open'">
                                <td>{{ moment(subscriptionLog.created_at).format('ll') }}</td>
                                <td>{{ selectedOrder.product ? selectedOrder.product.name : '-' }}</td>
                                <td>${{ subscriptionLog.amount ? parseFloat((parseFloat(subscriptionLog.amount) - parseFloat(subscriptionLog.discount_amount))).toFixed(2) : 0 }}</td>
                                <td><label>{{  Helper.capitalizeFirst(subscriptionLog.status) }}</label></td>
                                <td>
                                    <ul class="action_list">
                                        <li v-if="companyUserCan('create', 'checkouts')" title="Refund" @click="handlePaidSubscriptionRefund(subscriptionLog)"><i class="fas fa-reply"></i></li>
                                        <li title="Send Invoice" @click="sendSubscriptionChargeInvoice(subscriptionLog.id)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != subscriptionLog.id, 'fa fa-spin fa-spinner': sendInvoiceLoader == subscriptionLog.id }"></i></li>
                                    </ul>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination" v-show="paidSubscriptionLogs.total">
                <pagination v-model="paidSubscriptionParams.page" :pages="paidSubscriptionLogs.last_page" @update:modelValue="handlePagination('paid')" />
            </div>
            <div class="result_wpr mb-5">
                <div class="title">Failed Charge Summary</div>
                <div class="contact_loader" v-if="failedSubscriptionLogsLoader"><quote-loader /></div>
                <table v-show="!failedSubscriptionLogsLoader">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="failedSubscriptionLogs.total">
                        <tr v-for="(failedSubscriptionLog, f) in failedSubscriptionLogs.data" :key="f">
                            <td>{{ moment(failedSubscriptionLog.created_at).format('ll') }}</td>
                            <td>${{ failedSubscriptionLog.amount ? parseFloat(failedSubscriptionLog.amount).toFixed(2) : 0 }}</td>
                            <td>
                                <ul class="action_list">
                                    <li title="Refund"><i class="fas fa-reply not-refund"></i></li>
                                    <li title="Send Invoice"><i class="fas fa-paper-plane not-refund"></i></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination" v-show="failedSubscriptionLogs.total">
                <pagination v-model="failedSubscriptionParams.page" :pages="failedSubscriptionLogs.last_page" @update:modelValue="handlePagination('failed')" />
            </div>
            <div class="action_wpr">
                <button :disabled="cardUpdateEmailLoader" type="button" class="btn danger_btn" @click="handleCancelSubscription()">Cancel Subscription</button>
                <button :disabled="cardUpdateEmailLoader" class="btn save_btn" @click="handleUpdateCard()">Update Credit Card</button>
                <button :disabled="cardUpdateEmailLoader" class="btn save_btn" @click="handleUpdateEmail()"><i class="fa fa-spinner fa-spin" v-if="cardUpdateEmailLoader"></i> {{ cardUpdateEmailLoader ? 'Sending' : 'Send Card Update Email' }}</button>
            </div>
        </div>
    </div>

    <refund-component v-model="refundPayment" :refund-amount="refundAmount" :order-id="refundOrderId" :subscription-id="subscriptionId" :is-subscription="true" :refresh-records="handleRefreshRecords" />
    <update-card v-model="updateCard" :client="selectedClient" :processor="processor" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))
    const UpdateCard = defineAsyncComponent(() => import('@/pages/checkout/components/client/UpdateCard'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import moment from 'moment'

    export default {
        name: 'Checkout Subscription Details',

        data () {
            return {
                refundPayment: false,
                Helper,
                moment,
                paidSubscriptionParams: {
                    subscription_id: 0,
                    status: 'paid',
                    page: 1,
                    per_page: 10,
                },
                failedSubscriptionParams: {
                    subscription_id: 0,
                    status: 'open',
                    page: 1,
                    per_page: 10,
                },
                trialSubscriptionParams: {
                    subscription_id: 0,
                    status: 'trialing',
                    page: 1,
                    per_page: 10,
                },
                pauseTrial: 0,
                pauseBilling: 0,
                trialEndDate: '',
                nextBillingDate: '',
                refundAmount: 0,
                refundOrderId: 0,
                subscriptionId: 0,
                updateCard: false,
                processor: {},
            }
        },

        props: {
            modelValue: Boolean,
            selectedClient: {
                type: Object,
                default: () => {}
            },
            selectedOrder: {
                type: Object,
                default: () => {}
            },
            refreshSubscriptions: {
                type: Function,
                default: () => {}
            }
        },

        emit: [ 'update:modelValue' ],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'selectedOrder.next_billing_date' (date) {
                const vm = this;
                vm.nextBillingDate = date ? date : '';
            },
        },

        components: {
            RefundComponent,
            UpdateCard,
        },

        computed: {
            ...mapState({
                paidSubscriptionLogs: state => state.checkoutModule.paidSubscriptionLogs,
                paidSubscriptionLogsLoader: state => state.checkoutModule.paidSubscriptionLogsLoader,
                failedSubscriptionLogsLoader: state => state.checkoutModule.failedSubscriptionLogsLoader,
                failedSubscriptionLogs: state => state.checkoutModule.failedSubscriptionLogs,
                trialSubscriptionLogs: state => state.checkoutModule.trialSubscriptionLogs,
                trialSubscriptionLogsLoader: state => state.checkoutModule.trialSubscriptionLogsLoader,
                sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
                cardUpdateEmailLoader: state => state.checkoutModule.cardUpdateEmailLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        methods: {
            ...mapActions ({
                getPaidSubscriptionLogs: 'checkoutModule/getPaidSubscriptionLogs',
                getFailedSubscriptionLogs: 'checkoutModule/getFailedSubscriptionLogs',
                getTrialSubscriptionLogs: 'checkoutModule/getTrialSubscriptionLogs',
                updateOrderTrialStatus: 'checkoutModule/updateOrderTrialStatus',
                updateOrderBillingStatus: 'checkoutModule/updateOrderBillingStatus',
                updateTrialEndDate: 'checkoutModule/updateTrialEndDate',
                updateNextBillingDate: 'checkoutModule/updateNextBillingDate',
                cancelSubscription: 'checkoutModule/cancelSubscription',
                sendSubscriptionChargeInvoice: 'checkoutModule/sendSubscriptionChargeInvoice',
                sendCardUpdateEmail: 'checkoutModule/sendCardUpdateEmail',
            }),

            handleCancelSubscription () {
                const vm = this;

                if (vm.selectedOrder.status == 'canceled') {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already canceled, you can not cancel it again.`, 'OK'));
                } else {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to Cancel this Subscription?`, 'Yes', true);

                    options.preConfirm = function () {
                                            return vm.cancelSubscription(vm.selectedOrder.id).then((result) => {
                                                if (result) {
                                                    setTimeout(() => {
                                                        vm.refreshSubscriptions();
                                                        vm.closeModal();
                                                    }, 2000);
                                                }
                                            });
                                        };

                    Swal.fire(options);
                }
            },

            handlePaidSubscriptionLogs () {
                const vm = this;

                vm.paidSubscriptionParams.subscription_id = vm.selectedOrder.id;
                vm.paidSubscriptionParams.status          = 'paid';
                vm.paidSubscriptionParams.page            = 1;

                vm.getPaidSubscriptionLogs(vm.paidSubscriptionParams);
            },

            handleFailedSubscriptionLogs () {
                const vm = this;

                vm.failedSubscriptionParams.subscription_id = vm.selectedOrder.id;
                vm.failedSubscriptionParams.status          = 'open';
                vm.failedSubscriptionParams.page            = 1;

                vm.getFailedSubscriptionLogs(vm.failedSubscriptionParams);
            },

            handleTrialSubscriptionLogs () {
                const vm = this;

                vm.trialSubscriptionParams.subscription_id = vm.selectedOrder.id;
                vm.trialSubscriptionParams.status          = 'trialing';
                vm.trialSubscriptionParams.page            = 1;

                vm.getTrialSubscriptionLogs(vm.trialSubscriptionParams);
            },

            handlePagination (status) {
                const vm = this;

                if (status == 'paid') {
                    vm.getPaidSubscriptionLogs(vm.paidSubscriptionParams);
                } else if (status == 'failed') {
                    vm.getFailedSubscriptionLogs(vm.failedSubscriptionParams);
                } else if (status == 'trialing') {
                    vm.getTrialSubscriptionLogs(vm.trialSubscriptionParams);
                }
            },

            resetForm () {
                const vm = this;

                vm.pauseTrial      = vm.selectedOrder.is_trial_pause ? 1 : 0;
                vm.pauseBilling    = vm.selectedOrder.is_billing_pause ? 1 : 0;
                vm.trialEndDate    = vm.selectedOrder.trial_end ? vm.selectedOrder.trial_end : '';
                vm.nextBillingDate = vm.selectedOrder.next_billing_date ? vm.selectedOrder.next_billing_date : '';

                vm.handlePaidSubscriptionLogs();
                vm.handleFailedSubscriptionLogs();
                vm.handleTrialSubscriptionLogs();
            },

            handleOrderTrialStatus () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${vm.pauseTrial ? 'pause' : 'open' } this trial?`, 'Yes');

                options.preConfirm = function () {
                                        const form = { id: vm.selectedOrder.id, is_trial_pause: vm.pauseTrial };

                                        return vm.updateOrderTrialStatus(form).then((result) => {
                                            if (result) {
                                                vm.refreshSubscriptions();
                                            }
                                        });
                                    };

                Swal.fire(options).then(({ isConfirmed }) => {
                    if (!isConfirmed) {
                        vm.pauseTrial = vm.pauseTrial ? 0 : 1;
                    }
                });
            },

            handleOrderBillingStatus () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${vm.pauseBilling ? 'pause' : 'open' } this billing?`, 'Yes');

                options.preConfirm = function () {
                                        const form = { id: vm.selectedOrder.id, is_billing_pause: vm.pauseBilling };

                                        return vm.updateOrderBillingStatus(form).then((result) => {
                                            if (result) {
                                                vm.refreshSubscriptions();
                                            }
                                        });
                                    };

                Swal.fire(options).then(({ isConfirmed }) => {
                    if (!isConfirmed) {
                        vm.pauseBilling = vm.pauseBilling ? 0 : 1;
                    }
                });
            },

            handleTrialEndDate () {
                const vm = this;

                if (moment(vm.trialEndDate).isValid() && (vm.selectedOrder.trial_end != moment(vm.trialEndDate).format('YYYY-MM-DD'))) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You are updating your trial end date so your next billing date will be changed to  ${moment(vm.trialEndDate).format('ll')}. Proceed?`, 'Yes');

                    options.preConfirm = function () {
                                            const form = { id: vm.selectedOrder.id, trial_end: moment(vm.trialEndDate).format('YYYY-MM-DD') };

                                            return vm.updateTrialEndDate(form).then((result) => {
                                                if (result) {
                                                    vm.refreshSubscriptions();
                                                }
                                            });
                                        };

                    Swal.fire(options).then(({ isConfirmed }) => {
                        if (!isConfirmed) {
                            vm.trialEndDate = vm.selectedOrder.trial_end ? vm.selectedOrder.trial_end : '';
                        }
                    });
                }
            },

            handleNextBillingDate () {
                const vm = this;

                if (moment(vm.nextBillingDate).isValid() && vm.selectedOrder.next_billing_date != moment(vm.nextBillingDate).format('YYYY-MM-DD')) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to change next billing date?`, 'Yes');

                    options.preConfirm = function () {
                                            const form = { id: vm.selectedOrder.id, next_billing_date: moment(vm.nextBillingDate).format('YYYY-MM-DD') };

                                            return vm.updateNextBillingDate(form).then((result) => {
                                                if (result) {
                                                    vm.refreshSubscriptions();
                                                }
                                            });
                                        };

                    Swal.fire(options).then(({ isConfirmed }) => {
                        if (!isConfirmed) {
                            vm.nextBillingDate = vm.selectedOrder.next_billing_date ? vm.selectedOrder.next_billing_date : '';
                        }
                    });
                }
            },

            handlePaidSubscriptionRefund (order) {
                const vm = this;

                if (order.refunded_amount || order.is_refunded) {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already been refunded, you can not refund it again.`, 'OK'));
                } else {
                    vm.refundAmount     = parseFloat(order.amount).toFixed(2);
                    vm.refundOrderId    = order.order_id;
                    vm.subscriptionId   = order.id;
                    vm.refundPayment    = true;
                }
            },

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleUpdateCard () {
                const vm = this;

                vm.processor  = vm.selectedOrder.product.processor;
                vm.updateCard = true;
            },

            handleUpdateEmail () {
                const vm = this;

                vm.sendCardUpdateEmail(vm.selectedOrder.id);
            },

            handleRefreshRecords () {
                const vm = this;

                vm.refreshSubscriptions();
            },
        }
    }
</script>

<style scoped>
    .edit_details {
        /* width: calc(100% - 370px); */
        width: calc(100% - 100px);
        height: calc(100vh);
        background: #fafafb;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 12;
        animation: smoothMove 0.6s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    @keyframes smoothMove  {
        from  {transform: scale(0);}
        to  {transform: scale(1);}
    }

    .edit_details .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 30px;
        border-bottom: 1px solid #eee;
    }

    .edit_details .header h1 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
    }

    .edit_details .header .close_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: absolute;
        top: 20px;
        right: 30px;
        z-index: 6;
        cursor: pointer;
    }
    .edit_wpr  {
        padding: 30px 30px 0 30px;
        height: calc(100vh - 81px);
        overflow-y: scroll;
    }
    .edit_wpr .product_details{
        border: 1px solid #f3f3f3;
        background: #fff;
        padding: 30px 30px 10px 30px;
        margin-bottom: 40px;
        border-radius: 14px;
        text-align: left;
    }
    .edit_wpr .product_details .sub_header{
        margin: 0;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 12px;
    }
    .edit_wpr .listing, .edit_wpr .billing{
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        padding-bottom: 20px;
    }
    .edit_wpr .listing li, .edit_wpr .billing li{
        flex: 1 1 auto;
        padding: 25px 0;
    }
    .edit_wpr .listing li h4, .edit_wpr .billing li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 300;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .edit_wpr .listing li h6, .edit_wpr .billing li h6{
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 15px;
    }
    .edit_wpr .billing li{
        flex: 0 1 200px;
    }
    .edit_wpr .billing li :deep(.dp__input){
        height: 32px;
        font-size: 13px;
    }
    .result_wpr .title{
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        color: #121525;
        padding: 15px 20px;
        background: #fff;
        border-radius: 12px 12px 0 0;
        border-bottom: 1px solid #ddd;
    }
    .edit_wpr > .action_wpr {
        margin: 0 -30px;
        padding: 25px 30px;
        position: sticky;
        bottom: 0;
        right: 0;
        left: 0;
        background: #FAFAFB;
        border-top: 1px solid #eaeaea;
        z-index: 3;
        margin-top: auto;
    }
    .result_wpr table td, .result_wpr table th{
        text-align: left;
    }
    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
    }
    .result_wpr table td:last-child, .result_wpr table th:last-child {
        text-align: center;
    }
    .result_wpr table td .action_list{
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }

    .result_wpr table td .action_list li i.not-refund {
        color:#ff4343;
    }
</style>
